import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
  production: true,
  baseUrl: '',
  cardholderBaseUrl: 'https://galcard.eat.if.ua/cardholder',
  cardholderBaseLogin: '',
  cardholderBasePassword: '',
  zabbix: {
    zabbixUrl: 'http://95.67.66.131/zabbix/api_jsonrpc.php',
    zabbixDefaultGroups: ['15', '16'],
    zabbixPrinterGroupName: 'Printer_position_1',
    octGroups: ['17', '22', '24'],
    cvbGroups: ['18', '21', '25'],
    key_Parameters: 'vfs.file.contents[/root/ConvRoute/ZABB,total]',
    key_ip: 'system.loc_ip',
    key_gps: 'system.run["timeout 5 gpspipe -w -n 7 | grep -m 1 lat"]',
    key_reboot_oct: 'system.run[\"/sbin/ngreboot\"]',
    key_reboot_cvb: 'system.run[\"reboot\"]',
    key_ping: 'agent.ping',
    key_uptime: 'system.uptime',
    key_event_close: 'system.run[\">/root/ConvRoute/event_close\"]',
    key_event_upload: 'system.run[\">/root/ConvRoute/event_upload\"]',
    disablePrinter: false,
    disableGPS: false,
    depots: [{
      LEFT_TOP: {
        lat: 49.234328,
        lon: 28.434415
      },
      LEFT_BOTTOM: {
        lat: 49.231642,
        lon: 28.435007
      },
      RIGHT_BOTTOM: {
        lat: 49.232304,
        lon: 28.437598
      },
      RIGHT_TOP: {
        lat: 49.234304,
        lon: 28.437416
      }
    },
      {
        LEFT_TOP: {
          lat: 49.241054,
          lon: 28.455227
        },
        LEFT_BOTTOM: {
          lat: 49.239776,
          lon: 28.454514
        },
        RIGHT_BOTTOM: {
          lat: 49.238950,
          lon: 28.458047
        },
        RIGHT_TOP: {
          lat: 49.240188,
          lon: 28.458626
        }
      },
      {
        LEFT_TOP: {
          lat: 50.446958917,
          lon: 30.512603650
        },
        LEFT_BOTTOM: {
          lat: 50.426958917,
          lon: 30.512603650
        },
        RIGHT_BOTTOM: {
          lat: 50.426958917,
          lon: 30.532603650
        },
        RIGHT_TOP: {
          lat: 50.446958917,
          lon: 30.532603650
        }
      }
    ]
  },
  language: 'uk',
  logLevel: NgxLoggerLevel.ERROR,
  rowsInTable: 20,
  isDebugMode: false,
  countMonth: -12,
  countReportDaysDefore: 7,
  available_languages: ['uk', 'en'], // For ukrainian projects
  showLangIcon: false,
  develop_company: 'SYMBOL TRANSPORT',
  develop_company_link: 'https://symboltransport.com/',
  version: '2.1.4',
  // UAH RON RUB
  report_currency: 'UAH',
  project: 'Івано-Франківськ'
};
